#mapCursorDefault {
  cursor: grab;
}

#mapCursorDefault:active {
  cursor: grabbing;
}

#mapCursorMarker {
  cursor: url('../assets/markerIcons/map-marker.png') 18 36, auto;
}

#mapCursorMarker:active {
  cursor: grabbing;
}

#mapCursorDraw {
  cursor: url('../assets/markerIcons/map-blue-dot.png') 8 8, auto;
}